<template>
  <div>
    <page-title>Case Note</page-title>
    <page-subtitle>Edit or Modify case note here</page-subtitle>
    <div v-if="loadingCase"></div>
    <div v-else class="mt-5">
      <div class="bg-white p-5 rounded">
        <section-subtitle> Enter case details </section-subtitle>
        <div class="flex flex-col">
          <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <!-- <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Select Client</label
              >
              <v-select
                class="py-1"
                :options="clients"
                label="name"
                v-model="caseNote.clientId"
                :reduce="(client) => client._id"
              ></v-select>
            </div> -->
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Date</label
              >
              <t-input
                v-model="caseNote.date"
                class="focus:outline-none"
                type="date"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Country of Interest</label
              >
              <t-rich-select
                :options="counties"
                v-model="caseNote.countryOfInterest"
                placeholder="Select country of Interest"
                value-attribute="name"
                text-attribute="name"
              >
              </t-rich-select>
            </div>

            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Select Visa Class</label
              >
              <t-select
                class="focus:outline-none"
                type="date"
                v-model="caseNote.applicationType"
                aria-autocomplete="no"
                :options="[
                  { label: 'Visiting Visa', value: 'visiting' },
                  { label: 'Study Visa', value: 'student' },
                  { label: 'Skilled Worker', value: 'skilled-worker' },
                  { label: 'Skilled Trade', value: 'skilled-trade' }
                ]"
              ></t-select>
            </div>
            <div
              id="study-visa"
              v-if="caseNote.applicationType === 'student'"
              class="col-span-2"
            >
              <div>
                <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Current Education Level</label
                    >
                    <t-select
                      v-model="caseNote.currentEducationLevel"
                      :options="educationLevels"
                    >
                    </t-select>
                  </div>
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Education Pursuit</label
                    >
                    <t-select
                      v-model="caseNote.educationalPursuit"
                      :options="educationLevels"
                    >
                    </t-select>
                  </div>
                </div>
                <div class="mt-4">
                  <h4 class="text-sm mb-4 font-semibold">
                    Required Document for Admission
                  </h4>
                  <div class="grid md:grid-cols-2 grid-cols-1">
                    <div
                      v-for="n in caseNote.requiredAdmissionDocuments"
                      :key="n.document"
                      class="flex items-start space-x-2"
                    >
                      <t-checkbox
                        :id="n.document"
                        :name="n.document"
                        type="checkbox"
                        v-model="n.isAvailable"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          :for="n.document"
                          >{{ n.document }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <h4 class="text-sm mb-4 font-semibold">
                    Required Document for Study Permit Visa Application
                  </h4>
                  <div class="grid md:grid-cols-2 grid-cols-1">
                    <div
                      v-for="n in caseNote.requiredVisaApplicationDocuments"
                      :key="n.document"
                      class="flex items-start space-x-2"
                    >
                      <t-checkbox
                        :id="n.document"
                        :name="n.document"
                        type="checkbox"
                        v-model="n.isAvailable"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          :for="n.document"
                          >{{ n.document }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="skilled-trade"
              v-if="caseNote.applicationType === 'skilled-trade'"
              class="col-span-2"
            >
              <div>
                <div class="grid md:grid-cols-2 grid-cols-1 gap-5 mb-4">
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >NOC CODE</label
                    >
                    <t-input v-model="caseNote.nocCode"> </t-input>
                  </div>
                </div>
                <div class="flex items-start flex-wrap space-y-2">
                  <div class="flex items-start space-y-2 space-x-2">
                    <t-checkbox
                      v-model="caseNote.hasSecondarySchoolEducation"
                      type="checkbox"
                      name="hasSecondarySchoolEducation"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="hasSecondarySchoolEducation"
                        >Secondary School</label
                      >
                      <span class="text-xs text-gray-500"
                        >Successful Completion WAEC, NECO, GCE, with at least 6
                        Credits including English, Maths and 1 Science Subject
                      </span>
                    </div>
                  </div>
                  <div class="flex items-start space-x-2">
                    <t-checkbox
                      v-model="caseNote.hasCollegeEducation"
                      type="checkbox"
                      name="hasCollegeEducation"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="hasCollegeEducation"
                        >College</label
                      >
                      <span class="text-xs text-gray-500"
                        >Certificate, Diploma or Higher Diploma in your
                        occupation e.g City &amp; Guilds Certificate, NABTEB etc
                      </span>
                    </div>
                  </div>
                  <div class="flex space-x-3">
                    <div class="flex items-start space-x-2">
                      <t-checkbox
                        v-model="caseNote.hasFederalTradeTest"
                        type="checkbox"
                        name="hasFederalTradeTest"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          for="hasFederalTradeTest"
                          >Federal Trade Test up to Grade 1 Level</label
                        >
                        <span class="text-xs text-gray-500"> </span>
                      </div>
                    </div>
                    <div class="flex items-start space-x-2">
                      <t-checkbox
                        v-model="caseNote.hasApprenticeshipCert"
                        type="checkbox"
                        name="hasApprenticeshipCert"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          for="hasApprenticeshipCert"
                          >Apprenticeship Certificate</label
                        >
                        <span class="text-xs text-gray-500"> </span>
                      </div>
                    </div>
                    <div class="flex items-start space-x-2">
                      <t-checkbox
                        v-model="caseNote.hasAnyCompanyTraining"
                        type="checkbox"
                        name="hasAnyCompanyTraining"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          for="hasAnyCompanyTraining"
                          >Any other Company Training Certificate</label
                        >
                        <span class="text-xs text-gray-500"> </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-start space-x-2">
                    <t-checkbox
                      v-model="caseNote.hasIELTSRequirement"
                      type="checkbox"
                      name="hasIELTSRequirement"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="hasIELTSRequirement"
                        >English Language Skills</label
                      >
                      <span class="text-xs text-gray-500"
                        >Test results of IELTS (International English Languauge
                        Testing System),CLB Level 5 which shows the client have
                        scored at the following Minimum level
                        <br />
                        <b
                          >Listening 5.0, Speaking 5.0, Reading 3.5, Writing
                          4.0</b
                        >
                      </span>
                    </div>
                  </div>
                  <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div class="flex items-start space-y-2 space-x-2">
                      <t-checkbox
                        v-model="caseNote.hasWorkExperience"
                        type="checkbox"
                        name="hasWorkExperience"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          for="hasWorkExperience"
                          >Work Experience</label
                        >
                        <span class="text-xs text-gray-500"
                          >Minimum of 2-year experience within the last 5 years
                          of your occupation</span
                        >
                      </div>
                    </div>
                    <div class="form-control">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Select Year of Experience</label
                      >
                      <t-select
                        v-model="caseNote.yearsOfExperience"
                        :options="[0, 1, 2, 3, 4, 5, 6, 'Above']"
                      >
                      </t-select>
                    </div>
                  </div>

                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Qualification in Client Trade</label
                    >
                    <t-select
                      :options="[
                        'Employment letter from a Canadian Employer that you have been offered full time employment for at least 1 year. You must challenge the qualification',
                        'Letter from a provincial Government in Canada starting that client have work experience in there trader',
                        'None'
                      ]"
                    >
                    </t-select>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="skilled-worker"
              v-if="caseNote.applicationType === 'skilled-worker'"
              class="col-span-2"
            >
              <div>
                <div class="grid md:grid-cols-2 grid-cols-1 gap-5 mb-5">
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Current Education Level</label
                    >
                    <t-select
                      v-model="caseNote.currentEducationLevel"
                      :options="educationLevels"
                    >
                    </t-select>
                  </div>
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >NOC CODE</label
                    >
                    <t-input v-model="caseNote.nocCode"> </t-input>
                  </div>
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Marital Status</label
                    >
                    <t-select
                      v-model="caseNote.maritalStatus"
                      :options="martialStatus"
                    >
                    </t-select>
                  </div>
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Number of Family</label
                    >
                    <t-input v-model="caseNote.numberOfFamily" type="number">
                    </t-input>
                  </div>
                  <div class="form-control">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="name"
                      >Number of Children</label
                    >
                    <t-input v-model="caseNote.numberOfChildren" type="number">
                    </t-input>
                  </div>
                </div>
                <div class="flex items-start flex-wrap space-y-2">
                  <div class="grid md:grid-cols-2 grid-cols-1 gap-5">
                    <div class="flex items-start space-y-2 space-x-2">
                      <t-checkbox
                        v-model="caseNote.hasWorkExperience"
                        type="checkbox"
                        name="hasWorkExperience"
                      />
                      <div class="flex flex-col">
                        <label
                          class="text-xs font-semibold text-gray-800 mb-2 block"
                          for="hasWorkExperience"
                          >Work Experience</label
                        >
                        <span class="text-xs text-gray-500"
                          >Minimum of 1 year experience within the last 10 years
                          of your occupation</span
                        >
                      </div>
                    </div>
                    <div class="form-control">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Select Year of Experience
                      </label>
                      <t-select
                        v-model="caseNote.yearsOfExperience"
                        :options="[0, 1, 2, 3, 4, 5, 6, 'Above']"
                      >
                      </t-select>
                    </div>
                  </div>
                  <div class="flex items-start space-x-2">
                    <t-checkbox
                      v-model="caseNote.hasIELTSRequirement"
                      type="checkbox"
                      name="hasIELTSRequirement"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="hasIELTSRequirement"
                        >English Language Skills</label
                      >
                      <span class="text-xs text-gray-500"
                        >Test results of IELTS (International English Languauge
                        Testing System), which shows the client have scored at
                        the following Minimum level
                        <br />
                        <b
                          >Listening 6.0, Speaking 6.0, Reading 6.0, Writing
                          6.0</b
                        >
                      </span>
                    </div>
                  </div>
                  <div class="flex items-start space-x-2">
                    <t-checkbox
                      v-model="caseNote.hasEducationCredAssessment"
                      type="checkbox"
                      name="hasEducationCredAssessment"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="hasEducationCredAssessment"
                        >Educational Credential Assessment</label
                      >
                      <span class="text-xs text-gray-500"
                        >Clients foreign educational Credential i.e degree,
                        diploma or certificate must be assessed by one of the
                        organization designated by IRCC to be valid and equal to
                        a completed credentials in Canada
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex mt-4 items-start space-x-3 space-y-3">
                <div class="grid md:grid-cols-3 grid-cols-1 gap-5">
                  <div class="flex items-start col-span-2 space-x-2">
                    <t-checkbox
                      v-model="caseNote.meetsSelectionFactors"
                      type="checkbox"
                      name="meetsSelectionFactors"
                    />
                    <div class="flex flex-col">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="meetsSelectionFactors"
                        >Meet selection factor requirement</label
                      >
                      <span class="text-xs text-gray-500"
                        >Clients must score 67 points to pass form <br />
                        <b>Age | max: 12 </b>, <b>Language | max: 28</b>,
                        <b>Education | max: 25 </b>,
                        <b>Work Experience | max: 15</b>,
                        <b>Arranged Employment | max: 10</b>,
                        <b>Adaptability | max:10</b>
                      </span>
                    </div>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Age</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[0].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Language</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[1].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Education</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[2].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Work Experience</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[3].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Arranged Employment</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[4].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Adaptability</label
                    >
                    <t-input
                      v-model="caseNote.selectionFactors[5].clientScore"
                      class="focus:outline-none"
                      type="number"
                    ></t-input>
                  </div>
                  <div class="form-control mt-3">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      >Total</label
                    >
                    <p class="">{{ totalSelectionScore }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <div class="flex items-start space-x-2">
                  <t-checkbox
                    v-model="caseNote.hasProofOfFunds"
                    type="checkbox"
                    name="hasProofOfFunds"
                  />
                  <div class="flex flex-col">
                    <label
                      class="text-xs font-semibold text-gray-800 mb-2 block"
                      for="hasProofOfFunds"
                      >Proof of Funds</label
                    >
                    <!-- <span class="text-xs text-gray-500">
                      If client have an arranged employment in Canada,then they
                      must show concrete proof
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="flex space-x-10">
                <div class="flex w-full items-end space-x-5">
                  <div>
                    <div class="form-control w-full">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Select todo factor</label
                      >
                      <t-select
                        :options="[
                          'Secondary School Education',
                          'College Education',
                          'IELTS Requirement',
                          'Employment Letter',
                          'Selection Factor',
                          'Federal Trade Test',
                          'Apprenticeship Certificate',
                          'Any Company Training',
                          'Work Experience',
                          'Education Credentials',
                          'Proof of Funds'
                        ]"
                        v-model="actionForClient"
                        class="focus:outline-none"
                        type="text"
                        aria-autocomplete="no"
                      ></t-select>
                    </div>
                    <div class="form-control mt-3 w-full">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Todo List by Client</label
                      >
                      <t-input
                        v-model="todoForClient"
                        class="focus:outline-none"
                        type="text"
                        aria-autocomplete="no"
                      ></t-input>
                    </div>
                  </div>
                  <t-button
                    type="button"
                    @click="addTodo('client')"
                    class="h-10"
                    >Add</t-button
                  >
                </div>
                <div
                  class="
                    form-control
                    h-48
                    overflow-y-scroll
                    rounded
                    w-full
                    py-2
                    px-3
                    border border-primary-light
                  "
                >
                  <p class="text-xs font-semibold text-gray-800 mb-2 block">
                    Todo List by Clients
                  </p>
                  <ul>
                    <li
                      v-for="(n, l) in caseNote.clientTodo"
                      :key="l"
                      class="
                        flex
                        items-center
                        justify-between
                        text-sm
                        py-2
                        border-b border-primary-light
                      "
                    >
                      <div>
                        <span class="font-serif text-xs text-gray-500 block">
                          {{
                            n['hasSecondarySchoolEducation']
                              ? 'Secondary School Education'
                              : n['hasCollegeEducation']
                              ? 'College Education'
                              : n['hasIELTSRequirement']
                              ? 'IELTS Requirement'
                              : n['hasEmploymentLetter']
                              ? 'Employment Letter'
                              : n['meetsSelectionFactors']
                              ? 'Selection Factor'
                              : n['hasFederalTradeTest']
                              ? 'Federal Trade Test'
                              : n['hasAnyCompanyTraining']
                              ? 'Any Company Training'
                              : n['hasApprenticeshipCert']
                              ? 'Apprenticeship Certificate'
                              : n['hasWorkExperience']
                              ? 'Work Experience'
                              : n['hasEducationCredAssessment']
                              ? 'Education Credentials'
                              : n['hasProofOfFunds']
                              ? 'Proof of Funds'
                              : ''
                          }}
                        </span>

                        {{
                          n['hasSecondarySchoolEducation'] ||
                            n['hasCollegeEducation'] ||
                            n['hasIELTSRequirement'] ||
                            n['hasEmploymentLetter'] ||
                            n['meetsSelectionFactors'] ||
                            n['hasFederalTradeTest'] ||
                            n['hasApprenticeshipCert'] ||
                            n['hasAnyCompanyTraining'] ||
                            n['hasWorkExperience'] ||
                            n['hasEducationCredAssessment'] ||
                            n['hasProofOfFunds']
                        }}
                      </div>
                      <span
                        @click="removeTodo('client', l)"
                        class="text-accent cursor-pointer"
                        >remove</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr class="col-span-2" />
            <!-- <div class="col-span-2">
              <div class="flex items-end space-x-10">
                <div class="flex w-full items-end space-x-5">
                  <div>
                    <div class="form-control w-full">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Select todo factor Consultant</label
                      >
                      <t-select
                        :options="[
                          'Secondary School Education',
                          'College Education',
                          'IELTS Requirement',
                          'Employment Letter',
                          'Selection Factor',
                          'Federal Trade Test',
                          'Apprenticeship Certificate',
                          'Any Company Training',
                          'Work Experience',
                          'Education Credentials',
                          'Proof of Funds'
                        ]"
                        v-model="actionForConsultant"
                        class="focus:outline-none"
                        type="text"
                        aria-autocomplete="no"
                      ></t-select>
                    </div>
                    <div class="form-control mt-3 w-full">
                      <label
                        class="text-xs font-semibold text-gray-800 mb-2 block"
                        for="name"
                        >Todo List by Consultants</label
                      >
                      <t-input
                        v-model="todoForConsultant"
                        class="focus:outline-none"
                        type="text"
                        aria-autocomplete="no"
                      ></t-input>
                    </div>
                  </div>
                  <t-button
                    type="button"
                    @click="addTodo('consultant')"
                    class="h-10"
                    >Add</t-button
                  >
                </div>
                <div
                  class="
                    form-control
                    h-48
                    overflow-y-scroll
                    rounded
                    w-full
                    py-2
                    px-3
                    border border-primary-light
                  "
                >
                  <p class="text-xs font-semibold text-gray-800 mb-2 block">
                    Todo List by Consultant
                  </p>
                  <ul>
                    <li
                      v-for="(n, l) in caseNote.consultantTodo"
                      :key="l"
                      class="
                        flex
                        items-center
                        justify-between
                        text-sm
                        py-2
                        border-b border-primary-light
                      "
                    >
                      <div>
                        <span class="font-serif text-xs text-gray-500 block">
                          {{
                            n['hasSecondarySchoolEducation']
                              ? 'Secondary School Education'
                              : n['hasCollegeEducation']
                              ? 'College Education'
                              : n['hasIELTSRequirement']
                              ? 'IELTS Requirement'
                              : n['hasEmploymentLetter']
                              ? 'Employment Letter'
                              : n['meetsSelectionFactors']
                              ? 'Selection Factor'
                              : n['hasFederalTradeTest']
                              ? 'Federal Trade Test'
                              : n['hasAnyCompanyTraining']
                              ? 'Any Company Training'
                              : n['hasApprenticeshipCert']
                              ? 'Apprenticeship Certificate'
                              : n['hasWorkExperience']
                              ? 'Work Experience'
                              : n['hasEducationCredAssessment']
                              ? 'Education Credentials'
                              : n['hasProofOfFunds']
                              ? 'Proof of Funds'
                              : ''
                          }}
                        </span>

                        {{
                          n['hasSecondarySchoolEducation'] ||
                            n['hasCollegeEducation'] ||
                            n['hasIELTSRequirement'] ||
                            n['hasEmploymentLetter'] ||
                            n['meetsSelectionFactors'] ||
                            n['hasFederalTradeTest'] ||
                            n['hasApprenticeshipCert'] ||
                            n['hasAnyCompanyTraining'] ||
                            n['hasWorkExperience'] ||
                            n['hasEducationCredAssessment'] ||
                            n['hasProofOfFunds']
                        }}
                      </div>
                      <span
                        @click="removeTodo('consultant', l)"
                        class="text-accent cursor-pointer"
                        >remove</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div
              class="form col-span-2"
              v-if="
                caseNote.applicationType === 'skilled-worker' ||
                  caseNote.applicationType === 'skilled-trade'
              "
            >
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Select Consultation Remark</label
              >
              <template v-if="caseNote.applicationType === 'skilled-worker'">
                <div
                  v-for="(remark, i) in consultation_remarks_skilled_worker"
                  :key="i"
                >
                  <label class="text-sm text-black">
                    <t-checkbox
                      v-model="selectedRemarkEdited"
                      :value="remark"
                      name="consultation_remarks_skilled_worker"
                    />
                    <span class="ml-4">
                      {{ remark }}
                    </span>
                  </label>
                </div>
              </template>
              <template v-if="caseNote.applicationType === 'skilled-trade'">
                <div
                  v-for="(remark, i) in consultation_remarks_skilled_trader"
                  :key="i"
                >
                  <label class="text-sm text-black">
                    <t-checkbox
                      v-model="selectedRemarkEdited"
                      :value="remark"
                      name="consultation_remarks_skilled_trader"
                    />
                    <span class="ml-4">
                      {{ remark }}
                    </span>
                  </label>
                </div>
              </template>
            </div>
            <div class="form col-span-2">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Type any extra consultation Remark</label
              >
              <t-textarea
                v-model="caseNote.type_note"
                rows="5"
                class="focus:outline-none"
                type="type"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
          </form>
          <div class="flex space-x-5 items-center justify-end mt-5">
            <t-button
              @click="$router.go(-1)"
              class="border w-28 border-accent px-3 py-1 rounded text-accent"
              variant="error"
            >
              Cancel
            </t-button>
            <t-button
              @click="updateCase"
              class="w-28 flex justify-center items-center"
            >
              <span v-if="!addingCase">Save</span> <spinner v-else></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle'
import caseApi from '../../api/case'
import Spinner from '../../components/Utils/Spinner.vue'
import countries from '../../data/countries.json'
import {
  consultationRemarksSkilledTrader,
  consultationRemarksSkilledWorker,
  educationLevels,
  martialStatus
} from '../../utils/helpers'

export default {
  name: 'Case',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    martialStatus,
    educationLevels,
    clientId: '',
    clients: [],
    selectedRemarkEdited: [],
    actionForClient: 'Secondary School Education',
    todoForClient: '',
    todoForConsultant: '',
    actionForConsultant: 'Secondary School Education',
    counties: countries,
    consultation_remarks_skilled_worker: consultationRemarksSkilledWorker,
    consultation_remarks_skilled_trader: consultationRemarksSkilledTrader,
    caseNote: {
      applicationType: 'visiting',
      note: '',
      minSelectionPass: 69,
      selectionFactors: [
        {
          factor: 'Age',
          maxScore: 12,
          clientScore: 0
        },
        {
          factor: 'Language',
          maxScore: 28,
          clientScore: 0
        },
        {
          factor: 'Education',
          maxScore: 25,
          clientScore: 0
        },
        {
          factor: 'Work Experience',
          maxScore: 15,
          clientScore: 0
        },
        {
          factor: 'Arranged Employment',
          maxScore: 10,
          clientScore: 0
        },
        {
          factor: 'Adaptability',
          maxScore: 10,
          clientScore: 0
        },
        {
          factor: 'Total',
          maxScore: 100,
          clientScore: 0
        }
      ],
      clientTodo: [],
      consultantTodo: [],
      requiredAdmissionDocuments: [
        { document: 'Copies of Degree Certificate', isAvailable: false },
        { document: 'Birth Certificate', isAvailable: false },
        { document: 'Reference Letter', isAvailable: false },
        { document: 'Statement of Interests', isAvailable: false },
        { document: 'WAEC/NECO Scratch Card', isAvailable: false },
        { document: 'International Passport Data Page', isAvailable: false },
        { document: 'Transcripts', isAvailable: false },
        { document: 'CV', isAvailable: false },
        { document: 'WAEC/NECO Result', isAvailable: false },
        { document: 'NABTEB', isAvailable: false }
      ],
      requiredVisaApplicationDocuments: [
        { document: 'Admission Letter from the School', isAvailable: false },
        { document: 'International Passport Data Page', isAvailable: false },
        { document: 'Birth Certificate', isAvailable: false },
        { document: 'Marriage Certificate', isAvailable: false },
        { document: 'Custodianship for Minor', isAvailable: false },
        { document: 'Fixed Deposit', isAvailable: false },
        { document: 'Valuation Report Landed Properties', isAvailable: false },
        { document: 'Proof of Tuition Payment', isAvailable: false },
        { document: 'Two Passport Photograph (35cmx35cm)', isAvailable: false },
        { document: 'Degree Certificate', isAvailable: false },
        { document: 'Police Clearance', isAvailable: false },
        { document: 'Proof of Fund: Bank Statement', isAvailable: false },
        { document: 'Proof of Landed Properties', isAvailable: false },
        {
          document: 'Visa Fee(To pay at the collection center)',
          isAvailable: false
        }
      ]
    },
    addingCase: false,
    loadingCase: false
  }),
  created() {
    this.clientId = this.$route.params.clientId
    this.caseId = this.$route.params.caseId
    this.getCase()
  },
  computed: {
    totalSelectionScore() {
      let sum = 0
      for (let i = 0; i < this.caseNote.selectionFactors.length; i++) {
        const factor = this.caseNote.selectionFactors[i]
        if (factor.factor.toLowerCase() === 'total') {
          continue
        }
        sum += Number(factor.clientScore)
      }
      return sum
    }
  },
  methods: {
    async getCase() {
      this.loadingCase = true
      const res = await caseApi.caseNote().getOne({ caseId: this.caseId })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loadingCase = false
        return
      }
      this.loadingCase = false
      this.caseNote = res.data
      const caseNoteNote = this.caseNote.note
      // this.caseNote.selectedRemark = caseNoteNote.slice(
      //   0,
      //   caseNoteNote.length - 1
      // )
      this.selectedRemarkEdited = caseNoteNote.slice(0, caseNoteNote.length - 1)
      this.caseNote.type_note = caseNoteNote[caseNoteNote.length - 1]
    },
    addDocuments(event, type, name) {
      if (type === 'admission') {
        const found = this.caseNote.requiredAdmissionDocuments.findIndex(
          c => c.document === name
        )

        if (found < 0) {
          this.caseNote.requiredAdmissionDocuments.push({
            document: name,
            isAvailable: true
          })
        } else {
          const index = this.caseNote.requiredAdmissionDocuments.findIndex(
            e => e.document === name
          )
          this.caseNote.requiredAdmissionDocuments.splice(index, 1)
        }
      } else {
        const found = this.caseNote.requiredVisaApplicationDocuments.findIndex(
          c => c.document === name
        )
        if (found < 0) {
          this.caseNote.requiredVisaApplicationDocuments.push({
            document: name,
            isAvailable: true
          })
        } else {
          const index = this.caseNote.requiredVisaApplicationDocuments.findIndex(
            e => e.document === name
          )
          this.caseNote.requiredVisaApplicationDocuments.splice(index, 1)
        }
      }
    },
    async updateCase() {
      this.addingCase = true
      const note = this.selectedRemarkEdited
      note.push(this.caseNote.type_note)
      const res = await caseApi.caseNote().update(
        { caseId: this.caseId },
        {
          ...this.caseNote,
          note,
          clientId: this.clientId,
          applicationType: this.caseNote.applicationType
        }
      )
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.addingCase = false
        return
      }

      this.addingCase = false
      this.caseNote = {
        applicationType: '',
        clientTodo: [],
        consultantTodo: []
      }

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.$router.push({
        name: 'ClientProfile',
        params: { id: this.clientId },
        query: { tab: 'case' }
      })
    },
    addTodo(forUser) {
      // 'Secondary School Education',
      // 'College Education',
      // 'IELTS Requirement',
      // 'Employment Letter',
      // 'Selection Factor'

      if (forUser === 'client') {
        if (this.actionForClient === '') return
        switch (this.actionForClient) {
          case 'Secondary School Education':
            this.caseNote.clientTodo.push({
              hasSecondarySchoolEducation: this.todoForClient
            })
            break

          case 'College Education':
            this.caseNote.clientTodo.push({
              hasCollegeEducation: this.todoForClient
            })
            break

          case 'IELTS Requirement':
            this.caseNote.clientTodo.push({
              hasIELTSRequirement: this.todoForClient
            })
            break

          case 'Employment Letter':
            this.caseNote.clientTodo.push({
              hasEmploymentLetter: this.todoForClient
            })
            break

          case 'Selection Factor':
            this.caseNote.clientTodo.push({
              meetsSelectionFactors: this.todoForClient
            })
            break

          case 'Federal Trade Test':
            this.caseNote.clientTodo.push({
              hasFederalTradeTest: this.todoForClient
            })
            break

          case 'Apprenticeship Certificate':
            this.caseNote.clientTodo.push({
              hasApprenticeshipCert: this.todoForClient
            })
            break

          case 'Any Company Training':
            this.caseNote.clientTodo.push({
              hasAnyCompanyTraining: this.todoForClient
            })
            break

          case 'Work Experience':
            this.caseNote.clientTodo.push({
              hasWorkExperience: this.todoForClient
            })
            break

          case 'Education Credentials':
            this.caseNote.clientTodo.push({
              hasEducationCredAssessment: this.todoForClient
            })
            break

          case 'Proof of Funds':
            this.caseNote.clientTodo.push({
              hasProofOfFunds: this.todoForClient
            })
            break

          default:
            break
        }

        this.todoForClient = ''
      }

      if (forUser === 'consultant') {
        if (this.actionForConsultant === '') return
        switch (this.actionForConsultant) {
          case 'Secondary School Education':
            this.caseNote.consultantTodo.push({
              hasSecondarySchoolEducation: this.todoForConsultant
            })
            break

          case 'College Education':
            this.caseNote.consultantTodo.push({
              hasCollegeEducation: this.todoForConsultant
            })
            break

          case 'IELTS Requirement':
            this.caseNote.consultantTodo.push({
              hasIELTSRequirement: this.todoForConsultant
            })
            break

          case 'Employment Letter':
            this.caseNote.consultantTodo.push({
              hasEmploymentLetter: this.todoForConsultant
            })
            break

          case 'Selection Factor':
            this.caseNote.consultantTodo.push({
              meetsSelectionFactors: this.todoForConsultant
            })
            break

          case 'Federal Trade Test':
            this.caseNote.consultantTodo.push({
              hasFederalTradeTest: this.todoForConsultant
            })
            break

          case 'Apprenticeship Certificate':
            this.caseNote.consultantTodo.push({
              hasApprenticeshipCert: this.todoForConsultant
            })
            break

          case 'Any Company Training':
            this.caseNote.consultantTodo.push({
              hasAnyCompanyTraining: this.todoForConsultant
            })
            break

          case 'Work Experience':
            this.caseNote.consultantTodo.push({
              hasWorkExperience: this.todoForConsultant
            })
            break

          case 'Education Credentials':
            this.caseNote.consultantTodo.push({
              hasEducationCredAssessment: this.todoForConsultant
            })
            break

          case 'Proof of Funds':
            this.caseNote.consultantTodo.push({
              hasProofOfFunds: this.todoForConsultant
            })
            break

          default:
            break
        }

        this.todoForConsultant = ''
      }
    },
    removeTodo(forUser, index) {
      if (forUser === 'client') {
        this.caseNote.clientTodo.splice(index, 1)
      } else {
        this.caseNote.consultantTodo.splice(index, 1)
      }
    }
  }
}
</script>

<style></style>
